import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {FinanceChargeData} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent.types'
import {StateCodes} from '@possible/cassandra/src/types/types.mobile.generated'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

const FinanceChargeBreakdown: FC<FinanceChargeData> = (props): JSX.Element | null => {
  const {stateCode, cabFee, interestFee} = props
  const {t} = useTranslation(['LoanApproved', 'Common'])

  const defaultTopText = t('Common:Interest')
  const texasLowerText = t('CSOFee')
  const floridaLowerText = t('VerificationFee')

  const ohioRate = '10' // rate is only used for OH and is always 10%
  const ohioAPRStr = `(${t('OhioAPR')})`
  const ohioTopText = `${t('Common:Interest')} ${ohioAPRStr}`
  const ohioLowerText = `${t('MonthlyFee')} (${ohioRate}%)`

  let topText: string
  let lowerText: string

  if (stateCode !== StateCodes.Tx && stateCode !== StateCodes.Oh && stateCode !== StateCodes.Fl) {
    return null
  }

  switch (stateCode) {
    case StateCodes.Tx:
      topText = defaultTopText
      lowerText = texasLowerText
      break
    case StateCodes.Oh:
      topText = ohioTopText
      lowerText = ohioLowerText
      break
    case StateCodes.Fl:
      topText = defaultTopText
      lowerText = floridaLowerText
      break
    default:
      topText = ''
      lowerText = ''
      break
  }

  const textBoxWidth = stateCode === StateCodes.Tx ? 100 : 150

  return (
    <Box marginLeft={'small'}>
      <Box direction="row">
        <Box width={textBoxWidth}>
          <PFText variant={'p_sm'}>{topText}</PFText>
        </Box>
        <PFText variant={'p_sm_semibold'} color={'info'}>
          ${interestFee}
        </PFText>
      </Box>
      <Box direction="row">
        <Box width={textBoxWidth}>
          <PFText variant={'p_sm'}>{lowerText}</PFText>
        </Box>
        <PFText variant={'p_sm_semibold'} color={'info'}>
          ${cabFee}
        </PFText>
      </Box>
    </Box>
  )
}

export {FinanceChargeBreakdown}
