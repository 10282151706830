import React, {FC} from 'react'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgIconNames} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import {littleGap, smallGap} from 'src/designSystem/layout'

export type InfoIconGridItem = {
  icon: SvgIconNames
  title: string
  subtitle: string
}

type InfoIconGridProps = {
  items: InfoIconGridItem[]
}

const InfoIconGrid: FC<InfoIconGridProps> = ({items}) => {
  return (
    <Box
      direction={'row'}
      align={'center'}
      justify="center"
      alignSelf="start"
      wrap={'wrap'}
      gap={'small'}
    >
      {items.map((item, index) => (
        <Box
          key={`${item.title}-${index}`}
          direction={'column'}
          align={'center'}
          marginBottom={smallGap}
          testID={'Info-Icon-Grid-Item'}
        >
          <Box marginBottom={littleGap}>
            <SvgIcon
              name={item.icon}
              colorVariant={'default'}
              size={'enormous'}
              testID={`Icon-${item.icon}`}
            />
          </Box>
          <PFText variant={'p_semibold'} color={NamedColors.PRODUCT_BLUE}>
            {item.title}
          </PFText>
          <PFText variant={'p'} color={NamedColors.BLACK}>
            {item.subtitle}
          </PFText>
        </Box>
      ))}
    </Box>
  )
}

export {InfoIconGrid}
