import React, {useState} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {
  LoanSubmissionDocument,
  PartnerStoreProductAttributionDocument,
} from 'src/products/loans/LoanApplicationSubmission/operations/LoanSubmission.gqls'
import {
  TrySubmitReturnType,
  useLoanSubmission,
} from 'src/lib/loans/useLoanSubmission/useLoanSubmission'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {eligibleForPossibleAdvance} from 'src/lib/advance/CashAdvanceUtils'
import {AddressUpdateErrorModal} from 'src/products/general/AddressUpdates/AddressUpdateErrorModal'
import {getFormattedAddress, getPreferredAccountFormatted} from 'src/lib/user/utils'
import {getIsAppRevampFunctionalUpdatesEnabled} from 'src/lib/experimentation/appRevampFeatureFlag'
import {LoanSubmissionTemplate} from 'src/products/loans/LoanApplicationSubmission/LoanSubmissionTemplate'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {openContactUsForm} from 'src/lib/contactUs'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {useBankAggregator} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'
import {OfferApplicationWorkflowStackParams} from 'src/workflows/types'

export type LoanApplicationSubmissionGQLContainerProps = {
  onSubmitLoanApplication: (loan: TrySubmitReturnType) => Promise<void>
  isLoading?: boolean
}

/**
 * Container to retrieve and save any data for the Loan Submission screen using GraphQL APIs.
 */
const LoanApplicationSubmissionGQLContainer: React.FC<
  LoanApplicationSubmissionGQLContainerProps
  // eslint-disable-next-line complexity
> = (props) => {
  const {t} = useTranslation(['LoanSubmission'])
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const workflowNavigation =
    useNavigation<StackNavigationProp<OfferApplicationWorkflowStackParams>>()
  const {onSubmitLoanApplication, isLoading} = props
  const [trySubmit] = useLoanSubmission()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showAddressErrorModal, setShowAddressErrorModal] = useState(false)
  const hideAddressErrorModal = (): void => setShowAddressErrorModal(false)

  const isAppRevampFunctionalUpdatesEnabled = getIsAppRevampFunctionalUpdatesEnabled()

  const {openBankAggregator} = useBankAggregator(navigation)

  const {
    selectedData: applicationData,
    loading: isLoadingApplicationData,
    error: applicationDataError,
  } = useCassandraQuery(
    LoanSubmissionDocument,
    {
      fetchPolicy: 'cache-and-network',
      onError: (e) => {
        logOfferApplicationError(
          e,
          'LoanSubmission - LoanInitialApplicationSubmissionGQLContainer query failed', // the "LoanSubmission" text is required for a DataDog monitor
        )
      },
    },
    (data) => {
      return {
        ...data.me,
        currentPartnerOfferId: data.getCurrentOffer?.id,
        preferredAccount: data.me.bankAccounts.all?.find(
          (account) => account.preferredFundingSource,
        ),
      }
    },
  )

  const [applyPartnerOfferId] = useCassandraMutation(PartnerStoreProductAttributionDocument)

  const amount = applicationData?.onboarding?.loan?.amountSelected ?? ''
  const ssnLastFour = applicationData?.identification?.ssn?.mask ?? ''
  const fullName = `${applicationData?.profile?.name?.firstName} ${applicationData?.profile?.name?.lastName}`
  const email = applicationData?.profile?.email?.address ?? ''
  const birthDate = applicationData?.profile?.birthDate ?? ''
  const address = getFormattedAddress(applicationData?.profile?.home?.address)
  const appliedCount = applicationData?.loans.countApplied ?? 0
  const currentOfferId = applicationData?.currentPartnerOfferId
  const canUpdateAddress = applicationData?.canUpdateAddress ?? false
  const isAdvance = eligibleForPossibleAdvance(applicationData?.products)

  const handleOnLoanSubmission = async (): Promise<void> => {
    setIsSubmitting(true)
    const loan = await trySubmit(amount)
    if (appliedCount === 0 && currentOfferId && loan) {
      try {
        const result = await applyPartnerOfferId({
          variables: {offerId: currentOfferId, entityId: loan.id},
        })
        if (result.errors) {
          throw result.errors[0]
        }
      } catch (e) {
        // we want to log this error but not block the user from submitting the loan
        logOfferApplicationError(
          e,
          `LoanSubmission - Error while storing product attribution. offer_id: ${currentOfferId}, loan id: ${loan.id}`,
        )
      }
    }
    if (loan) {
      await onSubmitLoanApplication(loan)
    }
    setIsSubmitting(false)
  }

  const handleOnPressContactUs = (): void => {
    openContactUsForm(navigation)
  }

  const handleOnPressEdit = isAppRevampFunctionalUpdatesEnabled
    ? (fieldName: string): void => {
        let destination: keyof MainStackParamList
        let navigationProps: MainStackParamList[keyof MainStackParamList] = undefined

        switch (fieldName) {
          case 'address':
            if (canUpdateAddress) {
              destination = 'UpdateAddress'
            } else {
              setShowAddressErrorModal(true)
              return
            }
            break
          case 'loanAmount':
            destination = 'LoanAmount'
            navigationProps = {
              previousAmountSelected: amount,
            }
            break
          case 'bankLinking':
            void openBankAggregator({
              onBankLinkComplete: (): void => {
                workflowNavigation.push('PrimaryAccountSelection', {
                  isForReviewAndEdit: true,
                })
              },
            })
            return
          case 'name':
          case 'ssn':
          case 'birthDate':
          default:
            destination = 'VerifyYourIdentity'
            break
        }

        navigation.push(destination, navigationProps)
      }
    : undefined

  return (
    <BaseTemplate
      pageTitle={isAdvance ? t('ReviewToContinue') : t('ReviewAndSubmit')}
      isLoading={isLoadingApplicationData}
      error={applicationDataError}
    >
      <LoanSubmissionTemplate
        onSubmitLoanApplication={handleOnLoanSubmission}
        isSubmitBtnDisabled={isSubmitting || isLoading === true}
        isSubmitBtnLoading={isSubmitting}
        loanAmount={amount}
        ssnLastFour={ssnLastFour}
        fullName={fullName}
        address={address}
        birthDate={birthDate}
        email={email}
        bankAccount={getPreferredAccountFormatted(applicationData?.preferredAccount)}
        onPressContactUs={handleOnPressContactUs}
        onPressEdit={handleOnPressEdit}
        canUpdateAddress={canUpdateAddress}
        isAdvance={isAdvance}
      />
      <AddressUpdateErrorModal
        hideModal={hideAddressErrorModal}
        showModal={showAddressErrorModal}
        canUpdateAddress={canUpdateAddress}
      />
    </BaseTemplate>
  )
}

export {LoanApplicationSubmissionGQLContainer}
